<template>
  <div class="filter-sidebar">
    <div class="filter-sidebar-inner-scroll">
      <h2>फिल्टरहरू</h2>
      <vue-custom-scrollbar class="scroll-area">
        <div class="select-all-filter">
          <label for="selectAll" class="checkbox-wrapper">सबै छान्नु          
            <input
              type="checkbox"
              class="select-all-filter__input"
              id="selectAll"
              v-model="allSelected"
              @click="handleSelectAllClick"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div
          class="map-filter"
          v-for="(typeData, index) in categoryList"
          v-bind:key="index"
        >
          <label class="checkbox-wrapper map-filter__label" :for="typeData.value">
            {{typeData.text}}
            <input
              type="checkbox"
              class="map-filter__input"
              :id="typeData.value"
              :value="typeData.value"
              v-model="selectedCategory"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </vue-custom-scrollbar>
      </div>
  </div>
</template>

<script>
import { poiTypes } from "@/helper/mapper";
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  data() {
    return {
      selectedCategory: [],
      allSelected: true,
      categoryList: poiTypes
    };
  },
  components: {
    vueCustomScrollbar
  },
  mounted() {
    this.selectAllCategory();
  },
  methods: {
    /**
     * Toggle all selected option
     */
    changeMarkerType() {
      if (this.selectedCategory.length !== this.categoryList.length) {
        this.allSelected = false;
      }
      if (this.selectedCategory.length === this.categoryList.length) {
        this.allSelected = true;
      }
      this.$emit("changeInCategory", this.selectedCategory);
    },
    /**
     * Adds all the poi value to selectedCategory variable
     */
    selectAllCategory() {
      let ids = this.categoryList.map((category) => category.value);
      this.selectedCategory = ids;
    },
    /**
     * Deselects all the selected category
     */
    unCheckAllSelected() {
      this.selectedCategory = [];
    },
    /**
     * Handler for all selected toggle
     */
    handleSelectAllClick() {
      !this.allSelected ? this.selectAllCategory() : this.unCheckAllSelected();
    }
  },
  watch: {
    selectedCategory: {
      handler: "changeMarkerType"
    }
  }
};
</script>

<style lang="scss" scoped>
  .map-filter,.select-all-filter {
    margin-top: 64px;
    margin-right: 32px;

    @media screen and (max-width: 3199px) {
      margin-top: 22px;
      margin-right: 20px;
    }
  }
</style>
